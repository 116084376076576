<template>
  <b-card no-body class="mb-2">
    <b-card-header class="align-items-center">
      <h4 class="mr-auto mb-0">
        <unicon name="bookmark" width="18" height="18" class="mr-1"></unicon>
        {{ id != nullGuid ? "تفاصيل" : "إضافة" }} نشاط
      </h4>
      <small v-if="id != nullGuid">
        <strong>تاريخ النشاط : </strong
        ><small>{{ new Date(activityDto.date).toLocaleDateString("en-UK") }}</small>
      </small>
    </b-card-header>

    <ValidationObserver ref="activityDto">
      <b-form>
        <b-card-body>
          <b-card-text>
            <b-row>
              <b-col cols="12" md="6">
                <EKInputText
                  :rules="[
                    {
                      type: 'required',
                      message: 'اسم النشاط مطلوب',
                    },
                  ]"
                  label="اسم النشاط"
                  placeholder="ادخل اسم النشاط"
                  name="name"
                  v-model="activityDto.title"
                />
              </b-col>
              <b-col cols="12" md="6">
                <EKInputImage
                  label="صورة النشاط"
                  required
                  title="أرفق الملف هنا أو اضغط لتحميل"
                  :value="
                    activityDto.documents[0]
                      ? activityDto.documents[0].path
                        ? activityDto.documents[0].path.replace(
                            /^/,
                            $store.getters['app/domainHost'] + '/'
                          )
                        : ''
                      : ''
                  "
                  @input="addNewImage($event)"
                ></EKInputImage>
              </b-col>
              <b-col cols="12">
                <div class="editor-container mt-2">
                  <label class="col-form-label">نص النشاط</label>
                  <quill-editor
                    class="editor"
                    ref="myTextEditor"
                    :value="activityDto.description"
                    :options="editorOption"
                    @change="onEditorChange"
                  />
                </div>
                <small class="text-danger mt-5" v-show="!activityDto.description"
                  >نص النشاط مطلوب</small
                >
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
        <b-card-footer class="py-1 d-flex">
          <b-button
            v-if="id != nullGuid"
            class="mr-1"
            @click="submitUpdateActivity()"
            variant="primary"
          >
            تعديل
          </b-button>
          <b-button v-else class="mr-1" @click="submitAddActivity()" variant="primary">
            إضافة</b-button
          >
          <b-button variant="outline-primary" to="./">تراجع</b-button>
          <b-button
            variant="outline-danger"
            class="ml-auto"
            @click="deleteActivity(activityDto.id)"
            v-if="id != nullGuid"
            >حذف</b-button
          >
        </b-card-footer>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapActions, mapState } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { nullGuid } from "@/EK-core/util/global";
import hljs from "highlight.js";
import { handleDashLangChange } from "@/libs/event-bus";
export default {
  components: {
    ValidationObserver,
    EKInputImage,
    EKInputText,
    quillEditor,
  },
  props: {
    id: String,
  },
  data: () => ({
    editorOption: {
      modules: {
        toolbar: [["bold", "italic", "underline", "strike"], [{ align: [] }]],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
      placeholder: "ادخل شرح عن المرحلة",
    },
    nullGuid,
  }),
  computed: {
    ...mapState({
      activityDto: (state) => state.activity.activityDto,
    }),
  },
  created() {
    this.getActivityDetails(this.id);
     handleDashLangChange(() => {
         this.getActivityDetails(this.id);
     });
  },
  methods: {
    ...mapActions([
      "getActivityDetails",
      "addActivity",
      "updateActivity",
      "deleteActivity",
    ]),
    submitAddActivity() {
      this.$refs.activityDto.validate().then((success) => {
        if (success && this.activityDto.documents[0].file) {
          this.addActivity(this.activityDto).then(() => {
            this.$store.commit("RESET_ACTIVITY_DTO");
            this.$refs.activityDto.reset();
          });
        }
      });
    },
    submitUpdateActivity() {
      this.$refs.activityDto.validate().then((success) => {
        if (
          success &&
          (this.activityDto.documents[0].file || this.activityDto.documents[0].path)
        ) {
          this.updateActivity(this.activityDto);
        }
      });
    },
    onEditorChange(value) {
      this.activityDto.description = value.html;
    },
    addNewImage(img) {
      if (this.id != nullGuid && this.activityDto.documents[0].id != nullGuid)
        this.activityDto.documentIds.splice(0, 1);
      this.activityDto.documents[0] = {
        id: nullGuid,
        file: img,
        path: "",
      };
    },
  },
  beforeDestroy() {
    this.$store.commit("RESET_ACTIVITY_DTO");
    this.$refs.activityDto.reset();
  },
};
</script>
