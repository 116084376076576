var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_c('unicon',{staticClass:"mr-1",attrs:{"name":"bookmark","width":"18","height":"18"}}),_vm._v(" "+_vm._s(_vm.id != _vm.nullGuid ? "تفاصيل" : "إضافة")+" نشاط ")],1),(_vm.id != _vm.nullGuid)?_c('small',[_c('strong',[_vm._v("تاريخ النشاط : ")]),_c('small',[_vm._v(_vm._s(new Date(_vm.activityDto.date).toLocaleDateString("en-UK")))])]):_vm._e()]),_c('ValidationObserver',{ref:"activityDto"},[_c('b-form',[_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputText',{attrs:{"rules":[
                  {
                    type: 'required',
                    message: 'اسم النشاط مطلوب',
                  } ],"label":"اسم النشاط","placeholder":"ادخل اسم النشاط","name":"name"},model:{value:(_vm.activityDto.title),callback:function ($$v) {_vm.$set(_vm.activityDto, "title", $$v)},expression:"activityDto.title"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputImage',{attrs:{"label":"صورة النشاط","required":"","title":"أرفق الملف هنا أو اضغط لتحميل","value":_vm.activityDto.documents[0]
                    ? _vm.activityDto.documents[0].path
                      ? _vm.activityDto.documents[0].path.replace(
                          /^/,
                          _vm.$store.getters['app/domainHost'] + '/'
                        )
                      : ''
                    : ''},on:{"input":function($event){return _vm.addNewImage($event)}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"editor-container mt-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v("نص النشاط")]),_c('quill-editor',{ref:"myTextEditor",staticClass:"editor",attrs:{"value":_vm.activityDto.description,"options":_vm.editorOption},on:{"change":_vm.onEditorChange}})],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.activityDto.description),expression:"!activityDto.description"}],staticClass:"text-danger mt-5"},[_vm._v("نص النشاط مطلوب")])])],1)],1)],1),_c('b-card-footer',{staticClass:"py-1 d-flex"},[(_vm.id != _vm.nullGuid)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitUpdateActivity()}}},[_vm._v(" تعديل ")]):_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitAddActivity()}}},[_vm._v(" إضافة")]),_c('b-button',{attrs:{"variant":"outline-primary","to":"./"}},[_vm._v("تراجع")]),(_vm.id != _vm.nullGuid)?_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteActivity(_vm.activityDto.id)}}},[_vm._v("حذف")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }